import { AccountBalance } from 'domain/model/user';
import { FC } from 'react';
import { UserFinancesBalanceLayout } from '../layout';
import {
  UserFinanceBalanceSlotActionsAdapter,
  UserFinanceBalanceSlotExpireAdapter,
  UserFinanceBalanceSlotTitleAdapter,
  UserFinanceBalanceSlotValueAdapter,
} from './';

type UserFinanceBalanceBonusesAdapterProps = {
  readonly balance: AccountBalance;
};

export const UserFinanceBalanceBonusesAdapter: FC<UserFinanceBalanceBonusesAdapterProps> = props => {
  const { balance } = props;

  return (
    <UserFinancesBalanceLayout
      slots={[
        <UserFinanceBalanceSlotExpireAdapter
          key={'expire'}
          balance={balance}
        />,
        <UserFinanceBalanceSlotTitleAdapter
          key={'title'}
          balance={balance}
        />,
        <UserFinanceBalanceSlotValueAdapter
          key={'value'}
          balance={balance}
        />,
        <UserFinanceBalanceSlotActionsAdapter
          key={'actions'}
          balance={balance}
        />,
      ]}
    />
  );
};
