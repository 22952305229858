import { Typography } from '@mui/material';
import { EBalanceType } from 'domain/model/enums';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { getUserBalanceExpireMessage } from '../../utils';

type UserBalanceExpireProps = {
  readonly value: number;
  readonly type: EBalanceType;
  readonly expireDate: string;
};

const UserBalanceExpire = ({ value, type, expireDate }: UserBalanceExpireProps) => {
  return (
    <MPChip
      size='micro'
      color='warning'
      label={<Typography variant='subtitle2'>{getUserBalanceExpireMessage(value, type, expireDate)}</Typography>}
    />
  );
};

export default UserBalanceExpire;
