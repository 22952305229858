import { UserFinanceBalancesAdapter, UserFinanceHistoryAdapter, UserFinancesLoaderAdapter } from './adapters';
import { UserFinancesLayout } from './layout';

const Layout = UserFinancesLayout;

const UserFinancesContainer = () => {
  return (
    <Layout
      header='Мой баланс'
      loader={<UserFinancesLoaderAdapter />}
      balances={<UserFinanceBalancesAdapter />}
      history={<UserFinanceHistoryAdapter />}
    />
  );
};

export default UserFinancesContainer;
