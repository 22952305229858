import store from 'data/store/store';
import { AspOfferShort } from 'domain/model/aspOffer';
import { CorpOfferShort } from 'domain/model/corpOffer';
import {
  EBookingOfferSortType,
  ECorpOfferSortType,
  EOfferListType,
  EOfferStatus,
  EProductOfferSortType,
  ETradeOfferSortType,
} from 'domain/model/enums';
import { Category } from 'domain/model/nsi';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { getPartnerDeskDetailsRoute } from 'presentation/features/partnerDesk/entry';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useUserLocalSettings from '../../../../hooks/useUserLocalSettings';
import { PaginationSize } from '../../../../types';
import { partnerDeskDetailsStateSelector } from '../store/selectors';
import {
  partnerDeskAspOffersSetArgs,
  partnerDeskAspOffersSetIsNewFetching,
  partnerDeskAspOffersSetPage,
  partnerDeskAspOffersSetPageSize,
  partnerDeskAspOffersSetSort,
  partnerDeskBookingOffersSetArgs,
  partnerDeskBookingOffersSetIsNewFetching,
  partnerDeskBookingOffersSetPage,
  partnerDeskBookingOffersSetPageSize,
  partnerDeskBookingOffersSetSort,
  partnerDeskCorpOffersSetArgs,
  partnerDeskCorpOffersSetIsNewFetching,
  partnerDeskCorpOffersSetPage,
  partnerDeskCorpOffersSetPageSize,
  partnerDeskCorpOffersSetSort,
  partnerDeskProductOffersSetArgs,
  partnerDeskProductOffersSetIsNewFetching,
  partnerDeskProductOffersSetPage,
  partnerDeskProductOffersSetPageSize,
  partnerDeskProductOffersSetSort,
  partnerDeskStartSession,
  partnerDeskTradeOffersSetArgs,
  partnerDeskTradeOffersSetIsNewFetching,
  partnerDeskTradeOffersSetPage,
  partnerDeskTradeOffersSetPageSize,
  partnerDeskTradeOffersSetSort,
} from '../store/slice';
import { EPartnerDeskDetailsTab, getPartnerOffersListQueryParams, partnerDeskOffersListEmptyParams } from '../utils';

type UsePartnerDeskDetailsHandlersProps = {
  readonly guid: UUID;
  readonly partnerId: UUID;
  readonly categoryId?: Nullable<UUID>;
};

export type UsePartnerDeskDetails = {
  readonly onChangeTab: (tab: EPartnerDeskDetailsTab) => void;

  readonly onChangeCorpOffersSort: (sort: ECorpOfferSortType[]) => void;
  readonly onChangeCorpOffersPageSize: (type: EOfferListType, newPageSize: PaginationSize) => void;
  readonly onChangeCorpOffersPage: (type: EOfferListType, newPage: number) => void;
  readonly onChangeCorpOffersCategory: (category: Nullable<Category>) => void;
  readonly onShowCorpOffersCard: (corpOffer: CorpOfferShort) => void;

  readonly onChangeTradeOffersSort: (sort: ETradeOfferSortType[]) => void;
  readonly onChangeTradeOffersPageSize: (type: EOfferListType, newPageSize: PaginationSize) => void;
  readonly onChangeTradeOffersPage: (type: EOfferListType, newPage: number) => void;
  readonly onChangeTradeOffersCategory: (category: Nullable<Category>) => void;
  readonly onShowTradeOffersCard: (tradeOffer: TradeOfferShort) => void;

  readonly onChangeAspOffersSort: (sort: ETradeOfferSortType[]) => void;
  readonly onChangeAspOffersPageSize: (type: EOfferListType, newPageSize: PaginationSize) => void;
  readonly onChangeAspOffersPage: (type: EOfferListType, newPage: number) => void;
  readonly onChangeAspOffersCategory: (category: Nullable<Category>) => void;
  readonly onShowAspOffersCard: (aspOffer: AspOfferShort) => void;

  readonly onChangeProductOffersSort: (sort: EProductOfferSortType[]) => void;
  readonly onChangeProductOffersPageSize: (newPageSize: PaginationSize) => void;
  readonly onChangeProductOffersPage: (newPage: number) => void;

  readonly onChangeBookingOffersSort: (sort: EBookingOfferSortType[]) => void;
  readonly onChangeBookingOffersPageSize: (newPageSize: PaginationSize) => void;
  readonly onChangeBookingOffersPage: (newPage: number) => void;
  readonly onChangeBookingOffersCategory: (category: Nullable<Category>) => void;
};

const emptyOffersParams = partnerDeskOffersListEmptyParams;

const usePartnerDeskDetailsHandlers = (props: UsePartnerDeskDetailsHandlersProps): UsePartnerDeskDetails => {
  const { guid, partnerId, categoryId } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { webAnalytics } = useWebAnalytics();

  const { city } = useUserLocalSettings().settings;

  const getCurrentState = useCallback(() => {
    return partnerDeskDetailsStateSelector(store.getState());
  }, []);

  const onChangeTab = useCallback(
    (tab: EPartnerDeskDetailsTab) => {
      history.replace(getPartnerDeskDetailsRoute({ guid, id: partnerId, tab }));
    },
    [history, guid, partnerId]
  );

  // corp offers
  const onChangeCorpOffersSort = useCallback(
    (sort: ECorpOfferSortType[]) => {
      dispatch(partnerDeskCorpOffersSetSort({ type: EOfferListType.Common, sort }));
      dispatch(partnerDeskCorpOffersSetSort({ type: EOfferListType.Upcoming, sort }));
    },
    [dispatch]
  );

  const onChangeCorpOffersPageSize = useCallback(
    (type: EOfferListType, pageSize: PaginationSize) => {
      dispatch(partnerDeskCorpOffersSetPageSize({ type, pageSize }));
    },
    [dispatch]
  );

  const onChangeCorpOffersPage = useCallback(
    (type: EOfferListType, page: number) => {
      dispatch(partnerDeskCorpOffersSetPage({ type, page }));
    },
    [dispatch]
  );

  const onChangeCorpOffersCategory = useCallback(
    (category: Nullable<Category>) => {
      history.replace(
        getPartnerDeskDetailsRoute({
          guid,
          id: partnerId,
          categoryId: category?.id,
          tab: EPartnerDeskDetailsTab.CorpOffers,
        })
      );
    },
    [guid, partnerId, history]
  );

  const onShowCorpOffersCard = useCallback(
    (corpOffer: CorpOfferShort) => {
      webAnalytics.offerShow(corpOffer.id);
    },
    [webAnalytics]
  );

  // trade offers

  const onChangeTradeOffersSort = useCallback(
    (sort: ETradeOfferSortType[]) => {
      dispatch(partnerDeskTradeOffersSetSort({ type: EOfferListType.Common, sort }));
      dispatch(partnerDeskTradeOffersSetSort({ type: EOfferListType.Upcoming, sort }));
    },
    [dispatch]
  );

  const onChangeTradeOffersPageSize = useCallback(
    (type: EOfferListType, pageSize: PaginationSize) => {
      dispatch(partnerDeskTradeOffersSetPageSize({ type, pageSize }));
    },
    [dispatch]
  );

  const onChangeTradeOffersPage = useCallback(
    (type: EOfferListType, page: number) => {
      dispatch(partnerDeskTradeOffersSetPage({ type, page }));
    },
    [dispatch]
  );

  const onChangeTradeOffersCategory = useCallback(
    (category: Nullable<Category>) => {
      history.replace(
        getPartnerDeskDetailsRoute({
          guid,
          id: partnerId,
          categoryId: category?.id,
          tab: EPartnerDeskDetailsTab.TradeOffers,
        })
      );
    },
    [guid, partnerId, history]
  );

  const onShowTradeOffersCard = useCallback(
    (tradeOffer: TradeOfferShort) => {
      webAnalytics.offerShow(tradeOffer.id);
    },
    [webAnalytics]
  );

  // asp offers

  const onChangeAspOffersSort = useCallback(
    (sort: ETradeOfferSortType[]) => {
      dispatch(partnerDeskAspOffersSetSort({ type: EOfferListType.Common, sort }));
      dispatch(partnerDeskAspOffersSetSort({ type: EOfferListType.Upcoming, sort }));
    },
    [dispatch]
  );

  const onChangeAspOffersPageSize = useCallback(
    (type: EOfferListType, pageSize: PaginationSize) => {
      dispatch(partnerDeskAspOffersSetPageSize({ type, pageSize }));
    },
    [dispatch]
  );

  const onChangeAspOffersPage = useCallback(
    (type: EOfferListType, page: number) => {
      dispatch(partnerDeskAspOffersSetPage({ type, page }));
    },
    [dispatch]
  );

  const onChangeAspOffersCategory = useCallback(
    (category: Nullable<Category>) => {
      history.replace(
        getPartnerDeskDetailsRoute({
          guid,
          id: partnerId,
          categoryId: category?.id,
          tab: EPartnerDeskDetailsTab.AspOffers,
        })
      );
    },
    [guid, partnerId, history]
  );

  const onShowAspOffersCard = useCallback(
    (aspOffer: AspOfferShort) => {
      webAnalytics.offerShow(aspOffer.id);
    },
    [webAnalytics]
  );

  // product offers

  const onChangeProductOffersSort = useCallback(
    (sort: EProductOfferSortType[]) => {
      dispatch(partnerDeskProductOffersSetSort(sort));
    },
    [dispatch]
  );

  const onChangeProductOffersPageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(partnerDeskProductOffersSetPageSize(pageSize));
    },
    [dispatch]
  );

  const onChangeProductOffersPage = useCallback(
    (page: number) => {
      dispatch(partnerDeskProductOffersSetPage(page));
    },
    [dispatch]
  );

  // booking offers

  const onChangeBookingOffersSort = useCallback(
    (sort: EBookingOfferSortType[]) => {
      dispatch(partnerDeskBookingOffersSetSort(sort));
    },
    [dispatch]
  );

  const onChangeBookingOffersPageSize = useCallback(
    (pageSize: PaginationSize) => {
      dispatch(partnerDeskBookingOffersSetPageSize(pageSize));
    },
    [dispatch]
  );

  const onChangeBookingOffersPage = useCallback(
    (page: number) => {
      dispatch(partnerDeskBookingOffersSetPage(page));
    },
    [dispatch]
  );

  const onChangeBookingOffersCategory = useCallback(
    (category: Nullable<Category>) => {
      history.replace(
        getPartnerDeskDetailsRoute({
          guid,
          id: partnerId,
          categoryId: category?.id,
          tab: EPartnerDeskDetailsTab.BookingOffers,
        })
      );
    },
    [guid, partnerId, history]
  );

  useEffect(() => {
    dispatch(partnerDeskStartSession(guid));

    const state = getCurrentState();
    const currentGuid = state.guid;

    const isGuidChanged = currentGuid !== guid;

    // trade

    let tradeCommonArgs = state.tradeOffers.common;
    let tradeUpcomingArgs = state.tradeOffers.upcoming;

    if (isGuidChanged) {
      tradeCommonArgs = emptyOffersParams;
      tradeUpcomingArgs = emptyOffersParams;
    }

    dispatch(
      partnerDeskTradeOffersSetArgs({
        type: EOfferListType.Common,
        args: getPartnerOffersListQueryParams({
          partnerId,
          categoryId,
          sort: tradeCommonArgs.sort,
          page: tradeCommonArgs.page,
          pageSize: tradeCommonArgs.pageSize,
          statuses: [EOfferStatus.Active],
        }),
      })
    );

    dispatch(
      partnerDeskTradeOffersSetArgs({
        type: EOfferListType.Upcoming,
        args: getPartnerOffersListQueryParams({
          partnerId,
          categoryId,
          sort: tradeUpcomingArgs.sort,
          page: tradeUpcomingArgs.page,
          pageSize: tradeUpcomingArgs.pageSize,
          statuses: [EOfferStatus.Upcoming],
        }),
      })
    );

    // trade

    let aspCommonArgs = state.aspOffers.common;
    let aspUpcomingArgs = state.aspOffers.upcoming;

    if (isGuidChanged) {
      aspCommonArgs = emptyOffersParams;
      aspUpcomingArgs = emptyOffersParams;
    }

    dispatch(
      partnerDeskAspOffersSetArgs({
        type: EOfferListType.Common,
        args: getPartnerOffersListQueryParams({
          partnerId,
          categoryId,
          sort: aspCommonArgs.sort,
          page: aspCommonArgs.page,
          pageSize: aspCommonArgs.pageSize,
          statuses: [EOfferStatus.Active],
        }),
      })
    );

    dispatch(
      partnerDeskAspOffersSetArgs({
        type: EOfferListType.Upcoming,
        args: getPartnerOffersListQueryParams({
          partnerId,
          categoryId,
          sort: aspUpcomingArgs.sort,
          page: aspUpcomingArgs.page,
          pageSize: aspUpcomingArgs.pageSize,
          statuses: [EOfferStatus.Upcoming],
        }),
      })
    );

    // corp

    let corpCommonArgs = state.corpOffers.common;
    let corpUpcomingArgs = state.corpOffers.upcoming;

    if (isGuidChanged) {
      corpCommonArgs = emptyOffersParams;
      corpUpcomingArgs = emptyOffersParams;
    }

    dispatch(
      partnerDeskCorpOffersSetArgs({
        type: EOfferListType.Common,
        args: getPartnerOffersListQueryParams({
          partnerId,
          categoryId,
          sort: corpCommonArgs.sort,
          page: corpCommonArgs.page,
          pageSize: corpCommonArgs.pageSize,
          statuses: [EOfferStatus.Active],
        }),
      })
    );

    dispatch(
      partnerDeskCorpOffersSetArgs({
        type: EOfferListType.Upcoming,
        args: getPartnerOffersListQueryParams({
          partnerId,
          categoryId,
          sort: corpUpcomingArgs.sort,
          page: corpUpcomingArgs.page,
          pageSize: corpUpcomingArgs.pageSize,
          statuses: [EOfferStatus.Upcoming],
        }),
      })
    );

    // product

    let productArgs = state.productOffers.common;

    if (isGuidChanged) {
      productArgs = emptyOffersParams;
    }

    dispatch(
      partnerDeskProductOffersSetArgs(
        getPartnerOffersListQueryParams({
          partnerId,
          sort: productArgs.sort,
          page: productArgs.page,
          pageSize: productArgs.pageSize,
        })
      )
    );

    // booking

    let bookingArgs = state.bookingOffers.list;

    if (isGuidChanged) {
      bookingArgs = emptyOffersParams;
    }

    dispatch(
      partnerDeskBookingOffersSetArgs(
        getPartnerOffersListQueryParams({
          ...bookingArgs,
          partnerId,
          categoryId,
        })
      )
    );

    if (isGuidChanged) {
      dispatch(partnerDeskTradeOffersSetIsNewFetching(true));
      dispatch(partnerDeskAspOffersSetIsNewFetching(true));
      dispatch(partnerDeskCorpOffersSetIsNewFetching(true));
      dispatch(partnerDeskProductOffersSetIsNewFetching(true));
      dispatch(partnerDeskBookingOffersSetIsNewFetching(true));
    } else {
      dispatch(partnerDeskTradeOffersSetIsNewFetching(false));
      dispatch(partnerDeskAspOffersSetIsNewFetching(false));
      dispatch(partnerDeskCorpOffersSetIsNewFetching(false));
      dispatch(partnerDeskProductOffersSetIsNewFetching(false));
      dispatch(partnerDeskBookingOffersSetIsNewFetching(false));
    }
  }, [dispatch, guid, partnerId, categoryId, city?.name, getCurrentState]);

  return {
    onChangeTab,

    onChangeCorpOffersSort,
    onChangeCorpOffersPageSize,
    onChangeCorpOffersPage,
    onChangeCorpOffersCategory,
    onShowCorpOffersCard,

    onChangeTradeOffersSort,
    onChangeTradeOffersPageSize,
    onChangeTradeOffersPage,
    onChangeTradeOffersCategory,
    onShowTradeOffersCard,

    onChangeAspOffersSort,
    onChangeAspOffersPageSize,
    onChangeAspOffersPage,
    onChangeAspOffersCategory,
    onShowAspOffersCard,

    onChangeProductOffersSort,
    onChangeProductOffersPageSize,
    onChangeProductOffersPage,

    onChangeBookingOffersSort,
    onChangeBookingOffersPageSize,
    onChangeBookingOffersPage,
    onChangeBookingOffersCategory,
  };
};

export default usePartnerDeskDetailsHandlers;
