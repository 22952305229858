import { getCorpOffersListRoute } from 'presentation/features/offer/corp/routes';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { AppConfigurator } from 'system/appConfigurator';
import { BalanceSlotActions } from '../../layout/controls';
import { UserFinanceBalanceSlotAdapterProps } from './types';

export const UserFinanceBalanceSlotActionsAdapter: FC<UserFinanceBalanceSlotAdapterProps> = () => {
  const history = useHistory();
  const corpEarningUrl = AppConfigurator.getInstance().getCorpEarningsSystemUrl();

  const onBy = () => history.push(getCorpOffersListRoute({}));

  return (
    <BalanceSlotActions>
      <MPButton
        variant='contained'
        color='brand'
        fullWidth
        onClick={onBy}
      >
        Потратить
      </MPButton>
      {corpEarningUrl && (
        <MPButton
          variant='outlined'
          color='brand'
          fullWidth
          href={corpEarningUrl}
          target='_blank'
        >
          Как получить
        </MPButton>
      )}
    </BalanceSlotActions>
  );
};
