import React from 'react';
import { BalanceWrapper, SlotsGrid } from './controls';
import { UserFinancesBalanceLayoutType } from './types';

export const UserFinancesBalanceLayout: UserFinancesBalanceLayoutType = props => {
  const { slots } = props;

  return (
    <BalanceWrapper>
      {slots?.length > 0 && (
        <SlotsGrid>
          {slots.map((s, i) => {
            return s ? <React.Fragment key={i}>{s}</React.Fragment> : null;
          })}
        </SlotsGrid>
      )}
    </BalanceWrapper>
  );
};
