import { AccountBalance } from 'domain/model/user';
import { FC } from 'react';
import { UserBalanceValue } from '../../../components/balanceValue';

type UserFinanceBalanceBonusesAdapterProps = {
  readonly balance: AccountBalance;
};

export const UserFinanceBalanceValueAdapter: FC<UserFinanceBalanceBonusesAdapterProps> = props => {
  const {
    balance: { type, balance },
  } = props;

  return (
    <UserBalanceValue
      variant='h2'
      type={type}
      value={balance}
    />
  );
};
