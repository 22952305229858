import useCurrentUserBalances from 'presentation/features/user/current/useBalances';
import { MPGrid } from '../../../theme/ui-kit/grid';
import { UserBalanceValue } from '../../user/components/balanceValue';
import { useHistory } from 'react-router';
import { getUserFinancesRoute } from '../../user/entry';

const BalancesAdapter = () => {
  const history = useHistory();
  const balances = useCurrentUserBalances();

  const onClick = () => {
    history.push(getUserFinancesRoute({}));
  };

  return (
    balances &&
    balances.length > 0 && (
      <MPGrid
        container
        spacing={2}
      >
        {balances.map(balance => (
          <MPGrid
            item
            key={balance.type}
          >
            <UserBalanceValue
              type={balance.type}
              value={balance.balance}
              onClick={onClick}
            />
          </MPGrid>
        ))}
      </MPGrid>
    )
  );
};

export default BalancesAdapter;
