import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import UserFinancesContainer from '../../features/user/details/finances/container';

const UserFinancesScreen = () => {
  return (
    <RequiredGuidInLocationContainer>
      {() => (
        <ScreenLayout footer>
          <UserFinancesContainer />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default UserFinancesScreen;
