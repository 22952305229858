import { EBalanceType } from 'domain/model/enums';
import { AccountBalance } from 'domain/model/user';
import { FC } from 'react';
import { UserFinanceBalanceBonusesAdapter } from './balanceBonuses';

type UserBalanceAdapterProps = {
  readonly balance: AccountBalance;
};

export const UserFinanceBalanceAdapter: FC<UserBalanceAdapterProps> = ({ balance }) => {
  switch (balance.type) {
    case EBalanceType.Bonuses:
      return <UserFinanceBalanceBonusesAdapter balance={balance} />;
    case EBalanceType.ASP:
      return <UserFinanceBalanceBonusesAdapter balance={balance} />;
  }
};
