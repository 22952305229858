import { Fade, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { BalancesWrapper, ContentWrapper } from './controls';
import { UserFinancesLayoutType } from './types';

export const UserFinancesLayout: UserFinancesLayoutType = props => {
  const { header, balances, history, actions, loader, children } = props;

  return (
    <Fade in>
      <DefaultContentWrapper>
        <ContentWrapper>
          <Typography variant='h1'>{header}</Typography>
          <Splitter size={3} />

          {balances && (
            <>
              <BalancesWrapper>{balances}</BalancesWrapper>
              <Splitter size={4} />
            </>
          )}

          {history && (
            <>
              {history}
              <Splitter size={5} />
            </>
          )}

          {actions}

          {children}
          {loader}
        </ContentWrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};
