import { FC } from 'react';
import { UserFinanceBalanceValueAdapter } from '../';
import { BalanceSlotValue } from '../../layout/controls';
import { UserFinanceBalanceSlotAdapterProps } from './types';

export const UserFinanceBalanceSlotValueAdapter: FC<UserFinanceBalanceSlotAdapterProps> = ({ balance }) => {
  return (
    <BalanceSlotValue>
      <UserFinanceBalanceValueAdapter balance={balance} />
    </BalanceSlotValue>
  );
};
