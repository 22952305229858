import { Location } from 'history';
import UserFavoritesScreen from 'presentation/screen/userFavorites';
import UserOrdersScreen from 'presentation/screen/userOrders';
import UserProfileScreen from 'presentation/screen/userProfile';
import UserActivationsScreen from 'presentation/screen/userPromotions';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import rootRouting from '../../../routing';
import { EUserFavoritesTab, EUserFavoritesUrlParam } from './details/favorites/utils';
import { EUserOrdersTab } from './details/orders/utils';
import UserOrderScreen from 'presentation/screen/userOrder';
import UserFinancesScreen from 'presentation/screen/userFinances';
import { useAuthUser } from '../auth/provider/useAuthUser';

const root = rootRouting.user;

export enum EUserRoutePath {
  Orders = 'orders',
  Profile = 'profile',
  Activations = 'activations',
  Favorites = 'favorites',
  Finances = 'finances',
}

const routing = {
  root,
  orders: `${root}/${EUserRoutePath.Orders}`,
  order: `${root}/${EUserRoutePath.Orders}/:id`,
  activations: `${root}/${EUserRoutePath.Activations}`,
  profile: `${root}/${EUserRoutePath.Profile}`,
  favorites: `${root}/${EUserRoutePath.Favorites}`,
  finances: `${root}/${EUserRoutePath.Finances}`,
};

export enum EUserActivationsUrlParam {
  Tab = 'tab',
}

export enum EUserOrdersUrlParam {
  Tab = 'tab',
  OrderId = 'orderId',
}

export type UserOrdersLocationState = {
  readonly guid: UUID;
};

export type UserFinancesLocationState = {
  readonly guid: UUID;
};

export type UserFavoritesLocationState = {
  readonly guid: UUID;
};

export type UserPromotionsLocationState = {
  readonly guid: UUID;
};

type GetUserOrdersRoute = {
  readonly guid?: Nullable<UUID>;
  readonly tab?: Nullable<EUserOrdersTab>;
};

type GetUserFinancesRoute = {
  readonly guid?: Nullable<UUID>;
};

type GetUserPromotionsRoute = {
  readonly guid?: Nullable<UUID>;
};

type GetUserOrderRouteProps = {
  readonly id: UUID;
  readonly guid?: Nullable<UUID>;
};

type GetUserFavoritesRouteProps = {
  readonly guid?: Nullable<UUID>;
  readonly tab?: EUserFavoritesTab;
};

export const getUserProfileRoute = () => {
  return routing.profile;
};

export const getUserFavoritesRoute = (props: GetUserFavoritesRouteProps): Location<UserFavoritesLocationState> => {
  const { guid, tab } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EUserFavoritesUrlParam.Tab, tab);
  }

  return {
    pathname: routing.favorites,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getUserOrdersRoute = (props: GetUserOrdersRoute): Location<UserOrdersLocationState> => {
  const { guid, tab } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  if (tab) {
    searchParams.append(EUserOrdersUrlParam.Tab, tab);
  }

  return {
    pathname: routing.orders,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getUserFinancesRoute = (props: GetUserFinancesRoute): Location<UserFinancesLocationState> => {
  const { guid } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  return {
    pathname: routing.finances,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getUserOrderRoute = (props: GetUserOrderRouteProps): Location<UserOrdersLocationState> => {
  const { id, guid } = props;

  const newGuid = guid ?? uuidv4();

  return {
    pathname: routing.order.replace(':id', id),
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getUserActivationsRoute = (props: GetUserPromotionsRoute): Location<UserPromotionsLocationState> => {
  const { guid } = props;
  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  return {
    pathname: routing.activations,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

const UserEntry = () => {
  const { isCorpUser } = useAuthUser();

  return (
    <Switch>
      <Route
        exact
        path={routing.profile}
        component={UserProfileScreen}
      />
      <Route
        exact
        path={routing.orders}
        component={UserOrdersScreen}
      />
      <Route
        exact
        path={routing.order}
        component={UserOrderScreen}
      />
      <Route
        exact
        path={routing.favorites}
        component={UserFavoritesScreen}
      />
      <Route
        exact
        path={routing.activations}
        component={UserActivationsScreen}
      />
      {isCorpUser && (
        <Route
          exact
          path={routing.finances}
          component={UserFinancesScreen}
        />
      )}
      <Redirect
        path={routing.root}
        to={routing.profile}
      />
    </Switch>
  );
};

export default UserEntry;
