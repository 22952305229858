import { createSelector } from '@reduxjs/toolkit';
import { userApi } from 'data/api/user';
import { RootState } from 'data/store/store';

export const userFinancesIsFetchingSelector = createSelector(
  (store: RootState) => userApi.endpoints.getCurrentUser.select({})(store).isLoading,
  (store: RootState) => userApi.endpoints.getUserBalance.select()(store).isLoading,
  (isCurrentUserFetching, isUserBalanceFetching) => isCurrentUserFetching || isUserBalanceFetching
);

export const currentUserFinanceHistorySelector = (/*store: RootState*/) => {
  return [];
};
