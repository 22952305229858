import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { HeaderUserAnonymous } from 'presentation/features/header/components/user/anonymous';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import HeaderMenu from '../components/menu';
import { HeaderUserShort } from '../components/user';
import { useContextHandlers } from '../hooks/useContextHandlers';

const HeaderUserInfoAdapter = () => {
  const location = useLocation();

  const { login } = useAuth();
  const { user } = useAuthUser();
  const handlers = useContextHandlers();

  const [menuAnchorEl, setMenuAnchorEl] = useState<Nullable<HTMLElement>>(null);

  const onOpenMenu = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event?.currentTarget ?? document.body);
  }, []);

  const onCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const onLocationClick = useCallback(() => {
    onCloseMenu();
    handlers.showChangeLocationDialog();
  }, [handlers, onCloseMenu]);

  useEffect(() => {
    onCloseMenu();
  }, [location, onCloseMenu]);

  return useMemo(
    () =>
      user ? (
        <>
          <HeaderUserShort
            user={user}
            onClick={onOpenMenu}
          />
          <HeaderMenu
            anchorEl={menuAnchorEl}
            user={user}
            open={!!menuAnchorEl}
            onOpen={onOpenMenu}
            onClose={onCloseMenu}
            onLocationClick={onLocationClick}
          />
        </>
      ) : (
        <HeaderUserAnonymous onClick={login} />
      ),
    [user, onOpenMenu, menuAnchorEl, onCloseMenu, onLocationClick, login]
  );
};

export default HeaderUserInfoAdapter;
