import React from 'react';
import { UsePartnerDeskDetails } from './hooks/usePartnerDeskDetails';

export type PartnerDeskDetailsContextValue = UsePartnerDeskDetails;

export type PartnerDeskCorpOffersContextValue = Pick<
  PartnerDeskDetailsContextValue,
  | 'onChangeCorpOffersPage'
  | 'onChangeCorpOffersPageSize'
  | 'onChangeCorpOffersSort'
  | 'onChangeCorpOffersCategory'
  | 'onShowCorpOffersCard'
>;

export type PartnerDeskTradeOffersContextValue = Pick<
  PartnerDeskDetailsContextValue,
  | 'onChangeTradeOffersPage'
  | 'onChangeTradeOffersPageSize'
  | 'onChangeTradeOffersSort'
  | 'onChangeTradeOffersCategory'
  | 'onShowTradeOffersCard'
>;

export type PartnerDeskAspOffersContextValue = Pick<
  PartnerDeskDetailsContextValue,
  | 'onChangeAspOffersPage'
  | 'onChangeAspOffersPageSize'
  | 'onChangeAspOffersSort'
  | 'onChangeAspOffersCategory'
  | 'onShowAspOffersCard'
>;

export type PartnerDeskProductOffersContextValue = Pick<
  PartnerDeskDetailsContextValue,
  'onChangeProductOffersPage' | 'onChangeProductOffersPageSize' | 'onChangeProductOffersSort'
>;

export type PartnerDeskBookingOffersContextValue = Pick<
  PartnerDeskDetailsContextValue,
  | 'onChangeBookingOffersPage'
  | 'onChangeBookingOffersPageSize'
  | 'onChangeBookingOffersSort'
  | 'onChangeBookingOffersCategory'
>;

export const PartnerDeskDetailsContext = React.createContext<PartnerDeskDetailsContextValue>(
  {} as PartnerDeskDetailsContextValue
);
