import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import { EAppFeature, ESearchUrlParam } from 'presentation/types';
import { useLocation } from 'react-router';
import { SearchResultsLocationState } from '../../features/search/entry';
import SearchResultsContainer from '../../features/search/offers/container';
import { EOfferType } from '../../../domain/model/enums';
import useTechConfig from '../../hooks/useTechConfig';
import { useMemo } from 'react';

const SearchResultsScreen = () => {
  const location = useLocation<Nullable<SearchResultsLocationState>>();
  const { hasFeature } = useTechConfig();

  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get(ESearchUrlParam.Query);

  const offerType = useMemo<EOfferType[]>(() => {
    const result: EOfferType[] = [];

    if (hasFeature(EAppFeature.Trade)) {
      result.push(EOfferType.Trade);
    }
    if (hasFeature(EAppFeature.Booking)) {
      result.push(EOfferType.Booking);
    }
    if (hasFeature(EAppFeature.Product)) {
      result.push(EOfferType.Product);
    }
    if (hasFeature(EAppFeature.Asp)) {
      result.push(EOfferType.Asp);
    }

    return result;
  }, [hasFeature]);

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <SearchResultsContainer
            guid={guid}
            query={query}
            offerType={offerType}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default SearchResultsScreen;
