import useCurrentUserBalances from '../../../current/useBalances';
import { UserFinanceBalanceAdapter } from './balance';

export const UserFinanceBalancesAdapter = () => {
  const balances = useCurrentUserBalances();

  return balances?.map(balance => (
    <UserFinanceBalanceAdapter
      key={balance.type}
      balance={balance}
    />
  ));
};
